import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useModal from '../../utils/useModal'
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const SelectEditSpace = ({
  isShowing,
  toggle,
  setSpaceId,
  spaceId,
  toggleEditSpaceModal,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
  spaceSelectType,
  toggleListSpace,
  selectedArea,
  setSelectedArea,
}) => {
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [allAreas, setAllAreas] = useState([]);
  const [allFloors, setAllFloors] = useState([]);
  const [allSpaces, setAllSpaces] = useState([]);

  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const handleChange = (selected) => {
    setSpaceId(selected.value);
    setSelectedSpace(selected);
  };

  const handleChangeArea = (selected) => {
    setSelectedArea(selected);
  };

  const handleChangeFloor = (selected) => {
    setSelectedFloor(selected);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (spaceSelectType === "list") {
      toggleListSpace();
      toggle();
    } else if (spaceSelectType === "delete") {
      toggleConfirmationModal();
    } else {
      toggleEditSpaceModal();
      toggle();
    }
  };

  const deleteSpace = () => {
    (async () => {
      if (spaceId !== null) {
        await axios
          .delete(`${API_ROUTE}space/${spaceId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Espaço removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  // get all Floors to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllFloors = res.data.data.allFloorsBySchool.map((floor) => {
            return {
              value: floor._id,
              label: floor.name,
            };
          });
          setSelectedFloor(tmpAllFloors[0]);
          setAllFloors(tmpAllFloors);
        });
    })();
  }, [setSelectedSchoolCode]);

  useEffect(() => {
    if (selectedFloor) {
      (async () => {
        await axios
          .get(`${API_ROUTE}area/byFloor/${selectedFloor.value}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllAreas = res.data.data.allAreas.map((area) => {
              return {
                value: area._id,
                label: area.name,
              };
            });
            if (tmpAllAreas.length > 0) {
              setAllAreas(tmpAllAreas);
              setSelectedArea(tmpAllAreas[0]);
            } else {
              // if there is no area, hence there is no space
              setAllAreas([]);
              setSelectedArea(null);
              setAllSpaces([]);
              setSelectedSpace(null);
            }
          });
      })();
    }
  }, [selectedFloor]);

  useEffect(() => {
    if (selectedArea) {
      (async () => {
        await axios
          .get(`${API_ROUTE}space/byArea/${selectedArea.value}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllSpaces = res.data.data.allSpaces.map((space) => {
              return {
                value: space._id,
                label: space.name,
              };
            });
            if (tmpAllSpaces.length > 0) {
              setSpaceId(tmpAllSpaces[0].value);
              setAllSpaces(tmpAllSpaces);
              setSelectedSpace(tmpAllSpaces[0]);
            } else {
              setSpaceId(null);
              setAllSpaces([]);
              setSelectedSpace(null);
            }
          });
      })();
    }
  }, [selectedArea]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {spaceSelectType === "edit"
                    ? "Editar Espaço"
                    : spaceSelectType === "delete"
                    ? "Remover Espaço"
                    : "Listar Espaços"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Selecione um Piso</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um piso"
                    options={allFloors}
                    className="react-select"
                    value={selectedFloor}
                    onChange={handleChangeFloor}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Selecione uma Área</Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de uma área"
                    options={allAreas}
                    className="react-select"
                    value={selectedArea}
                    onChange={handleChangeArea}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                {spaceSelectType === "list" ? null : (
                  <Form.Group controlId="validationCustomCoordinates">
                    <Form.Label>
                      {spaceSelectType === "edit"
                        ? "Selecione um Espaço para editar"
                        : spaceSelectType === "delete"
                        ? "Selecione um Espaço para remover"
                        : null}
                    </Form.Label>
                    <Select
                      placeholder="Selecione ou digite o nome de um espaço"
                      options={allSpaces}
                      className="react-select"
                      value={selectedSpace}
                      onChange={handleChange}
                      styles={customStyles}
                      error={true}
                    />
                  </Form.Group>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="success"
                  disabled={
                    spaceSelectType === "list"
                      ? selectedArea
                        ? false
                        : true
                      : selectedSpace
                      ? false
                      : true
                  }
                >
                  {spaceSelectType === "edit"
                    ? "Selecionar Espaço"
                    : spaceSelectType === "delete"
                    ? "Remover Espaço"
                    : "Listar Espaços"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteSpace}
            titleText={"Remover Espaço"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectEditSpace;
