let API_ROUTE = "";
if (process.env.NODE_ENV === "development") {
  API_ROUTE = "http://localhost:3001/api/v1/";
}
if (process.env.NODE_ENV === "staging") {
  API_ROUTE = "https://reservasdev.estig.ipb.pt/api/v1/";
}
if (process.env.NODE_ENV === "production") {
  API_ROUTE = "https://reservas.ipb.pt/api/v1/";
}

export default API_ROUTE;
