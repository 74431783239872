import React, {useState, useEffect, useRef, useContext} from "react";
import axios from "axios";
import { Tab, Row, Col, Nav } from "react-bootstrap";

import videoUrl from "../../video/howToObtainCoordinates.mp4";
import Select from "react-select";
import "./system-info.styles.scss";

import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const SystemInfo = () => {
  const downloadRef = useRef(null);
  const [img, setImg] = useState(""); // value used in select button

  const [imgOptions, setImgOptions] = useState([]); // data coming from db

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const handleChangeImg = (selected) => {
    setImg(selected.value);
  };

  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allFloors = res.data.data.allFloorsBySchool;

          const allFloorOptions = allFloors.map((floor) => {
            return {
              value: floor.img,
              label: floor.name,
            };
          });

          const groupedFloorOptions = [
            {
              label: "Piso",
              options: allFloorOptions,
            },
          ];

          setImgOptions(groupedFloorOptions);
        });
    })();

    (async () => {
      await axios
        .get(`${API_ROUTE}area/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allAreas = res.data.data.allAreasBySchool;

          const allAreaOptions = allAreas.map((area) => {
            return {
              value: area.img,
              label: area.name,
            };
          });

          const groupedAreaOptions = [
            {
              label: "Área",
              options: allAreaOptions,
            },
          ];

          setImgOptions((prevState) => {
            const tmp = [...prevState];

            tmp.push(...groupedAreaOptions);

            return [...tmp];
          });
        });
    })();
  }, [setSelectedSchoolCode]);

  const imgPreview = () => {
    if (img === "") return;
    return (
      <img
        src={`${API_ROUTE}images/${img}`}
        style={{ width: "20vh", marginTop: "10px" }}
      />
    );
  };

  return (
    <div
      className=""
      style={{
        width: "100%",
        paddingLeft: "5%",
        paddingRight: "5%",
        marginTop: "1rem",
      }}
    >
      <h1>Informações do Sistema</h1>

      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  Como obter as coordenadas de uma área específica na imagem.
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  Fazer Download das Plantas{" "}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>

          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <p>
                  O vídeo abaixo mostra como obter as coordenadas de uma área
                  específica de uma imagem. As coordenadas são necessárias para
                  criar áreas e espaços!
                </p>
                <video controls style={{ width: "50vw", height: "40vh" }}>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <p>Selecione uma imagem para fazer o download.</p>
                <Select
                  placeholder="Selecione ou digite o nome de uma imagem"
                  options={imgOptions}
                  onChange={handleChangeImg}
                  // menuIsOpen={true}`../src/img/${img}`
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="systemInfo--downloadBtn"
                  ref={downloadRef}
                  href={img !== "" ? `${API_ROUTE}images/${img}` : ""}
                  download={img !== "" ? `${API_ROUTE}images/${img}` : ""}
                  title="FloorPlanImage"
                >
                  Download
                </a>
                {imgPreview()}
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default SystemInfo;
