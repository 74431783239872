import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [selectedSchoolCode, setSelectedSchoolCode] = useState(0);
    const [userSchoolCode, setUserSchoolCode] = useState(0);

    return (
        <GlobalContext.Provider value={{ selectedSchoolCode, setSelectedSchoolCode, userSchoolCode, setUserSchoolCode }}>
            {children}
        </GlobalContext.Provider>
    );
};
