import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import useModal from "../../utils/useModal";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const ManagePermissionModal = ({
  isShowing,
  toggle,
  setActionFeedbackModalTitleText,
  setActionFeedbackModalAction,
  toggleActionFeedbackModal,
}) => {
  const {
    isShowing: isShowingConfirmationModal,
    toggle: toggleConfirmationModal,
  } = useModal();

  const handleSubmit = (event) => {
    event.preventDefault();
    toggleConfirmationModal();
  };

  const handleSubmitRequest = () => {
    if (!selectedEmployee || !selectedRole) return;
    (async () => {
      await axios
        .patch(
          `${API_ROUTE}employee/${selectedEmployee.value}`,
          { role: selectedRole.value },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            setActionFeedbackModalTitleText(
              "Permissão de utilizador alterada com sucesso!"
            );
            setActionFeedbackModalAction(true);
            toggleActionFeedbackModal();
            toggle();
          } else {
            const formattedSpaceNames = res.data.data.spaceNames.map(
              (spaceName) => <li>{spaceName}</li>
            );
            const spaceNameList = (
              <ul style={{ fontSize: "1rem", marginLeft: "15px" }}>
                {formattedSpaceNames}
              </ul>
            );
            const titleMarkup = (
              <div style={{ whiteSpace: "pre" }}>
                <p>
                  O(s) seguinte(s) espaço(s) utilizam este utilizador como
                  gestor: <br />
                  Para alterar sua permissão os espaços devem possuir outro
                  gestor!
                </p>
                {spaceNameList}
              </div>
            );

            setActionFeedbackModalTitleText(titleMarkup);
            setActionFeedbackModalAction(false);
            toggleActionFeedbackModal();
            toggle();
          }
        })
        .catch((err) => {
          alert("Smething went wrong");
        });
    })();
  };

  const [allRoles, setAllRoles] = useState([
    { value: "user", label: "Utilizador Comum" },
    { value: "system-manager", label: "Gestor do Sistema" },
    { value: "space-manager", label: "Gestor de Espaço" },
  ]);
  const [selectedRole, setSelectedRole] = useState(null);

  const [allEmployees, setAllEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}employee/getAll/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const allEmployees = res.data.data.employees;

          const allEmployeesOptions = allEmployees.map((employee) => {
            return {
              value: employee._id,
              label: employee.name,
              role: employee.role,
            };
          });

          setAllEmployees(allEmployeesOptions);
        });
    })();
  }, [setSelectedSchoolCode]);

  const handleSelectInputChange = (selected) => {
    setSelectedEmployee(selected);
    handleChangeRole(allRoles.find((role) => role.value === selected.role));
  };
  const handleChangeRole = (selected) => {
    setSelectedRole(selected);
  };

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Gerir Permissão</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    Selecione um utilizador para gerir permissão
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um utilizador"
                    options={allEmployees}
                    className="react-select"
                    value={selectedEmployee}
                    onChange={handleSelectInputChange}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>

                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>Selecione uma permissão</Form.Label>
                  <Select
                    placeholder="Selecione uma permissão"
                    options={allRoles}
                    className="react-select"
                    value={selectedRole}
                    onChange={handleChangeRole}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Alterar permissão
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={handleSubmitRequest}
            titleText={"Alterar permissão de utilizador"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ManagePermissionModal;
