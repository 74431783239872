import React from "react";
import ReactDOM from "react-dom";
import { FaExclamationTriangle } from "react-icons/fa"; // Importing the warning icon
import { Modal } from "react-bootstrap";

const WarningFeedbackModal = ({ isShowing, toggle, titleText }) => {
    return isShowing
        ? ReactDOM.createPortal(
            <React.Fragment>
                <Modal
                    show={isShowing}
                    onHide={() => {
                        toggle();
                        window.location.reload();
                    }}
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{titleText}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <FaExclamationTriangle
                                style={{ height: "15rem", width: "10rem", fill: "orange" }}
                            />
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>,
            document.body
        )
        : null;
};

export default WarningFeedbackModal;
