import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { FaRegTrashAlt, FaPencilAlt } from "react-icons/fa";

import useModal from '../../utils/useModal'
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";

import "./list-floor-area-space-modal.styles.scss";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const ListFloorAreaSpaceModal = ({
  setDocumentId,
  isShowing,
  toggle,
  toggleEdit,
  listType,
  documentId,
  toggleActionFeedback,
  setIsActionCompleted,
  setTitleText,
  setIsEdit,
}) => {
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [allDocuments, setAllDocuments] = useState([]);
  const [clickedId, setClickedId] = useState(null);

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  useEffect(() => {
    if (listType === "floor") {
      (async () => {
        await axios
          .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllFloors = res.data.data.allFloorsBySchool.map((floor) => {
              return {
                value: floor._id,
                label: floor.name,
              };
            });
            setAllDocuments(tmpAllFloors);
          });
      })();
    } else if (listType === "area") {
      if (documentId) {
        (async () => {
          await axios
            .get(`${API_ROUTE}area/byFloor/${documentId}`, {
              withCredentials: true,
            })
            .then((res) => {
              const tmpAllAreas = res.data.data.allAreas.map((area) => {
                return {
                  value: area._id,
                  label: area.name,
                };
              });
              if (tmpAllAreas.length > 0) {
                setAllDocuments(tmpAllAreas);
              } else {
                setAllDocuments([]);
              }
            });
        })();
      }
    } else if (listType === "spaceType") {
      (async () => {
        await axios
          .get(`${API_ROUTE}type/school/${selectedSchoolCode}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllSpaceTypes = res.data.data.allTypesBySchool.map((type) => {
              return {
                value: type._id,
                label: type.name,
              };
            });
            setAllDocuments(tmpAllSpaceTypes);
          });
      })();
    } else if (listType === "resource") {
      (async () => {
        await axios
          .get(`${API_ROUTE}resource/school/${selectedSchoolCode}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllResources = res.data.data.allResourcesBySchool.map(
              (resource) => {
                return {
                  value: resource._id,
                  label: resource.name,
                };
              }
            );
            setAllDocuments(tmpAllResources);
          });
      })();
    } else if (listType === "reason") {
      (async () => {
        await axios
          .get(`${API_ROUTE}reason/school/${selectedSchoolCode}`, {
            withCredentials: true,
          })
          .then((res) => {
            const tmpAllReasons = res.data.data.allReasonsBySchool.map((reason) => {
              return {
                value: reason._id,
                label: reason.designation,
              };
            });
            setAllDocuments(tmpAllReasons);
          });
      })();
    }
  }, [setSelectedSchoolCode]);

  useEffect(() => {
    if (listType === "area") {
      if (documentId) {
        (async () => {
          await axios
            .get(`${API_ROUTE}area/byFloor/${documentId}`, {
              withCredentials: true,
            })
            .then((res) => {
              const tmpAllAreas = res.data.data.allAreas.map((area) => {
                return {
                  value: area._id,
                  label: area.name,
                };
              });
              if (tmpAllAreas.length > 0) {
                setAllDocuments(tmpAllAreas);
              } else {
                setAllDocuments([]);
              }
            });
        })();
      }
    }
    if (listType === "space") {
      if (documentId) {
        (async () => {
          await axios
            .get(`${API_ROUTE}space/byArea/${documentId}`, {
              withCredentials: true,
            })
            .then((res) => {
              const tmpAllSpaces = res.data.data.allSpaces.map((space) => {
                return {
                  value: space._id,
                  label: space.name,
                };
              });
              if (tmpAllSpaces.length > 0) {
                setAllDocuments(tmpAllSpaces);
              } else {
                setAllDocuments([]);
              }
            });
        })();
      }
    }
  }, [documentId]);

  const deleteResource = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}resource/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Recurso removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  const deleteArea = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}area/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Área removida com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  const deleteFloor = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}floor/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Piso removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  const deleteSpace = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}space/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Espaço removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  const deleteSpaceType = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}type/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const spacesUsingThisType = res.data.data.spaces;
            if (spacesUsingThisType.length > 0) {
              const spacesNames = spacesUsingThisType.map(
                (space) => space.name
              );
              setTitleText(
                `O(s) seguinte(s) espaço(s) utilizam este tipo: ${spacesNames}, altere o(s) tipo(s) antes de removê-lo(s)!`
              );
              setIsActionCompleted(false);
            } else {
              setTitleText("Tipo de espaço removido com sucesso!");
              setIsActionCompleted(true);
            }
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
          })
          .finally(() => {
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          });
      }
    })();
  };

  const deleteReason = () => {
    (async () => {
      if (clickedId !== null) {
        await axios
          .delete(`${API_ROUTE}reason/${clickedId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const reservationsUsingThisReason = res.data.data.reasons;
            if (reservationsUsingThisReason.length > 0) {
              const reservationsIds = reservationsUsingThisReason.map(
                (reservation) => reservation._id
              );
              setTitleText(
                `A(s) seguinte(s) reservas(s) utilizam este motivo: ${reservationsIds}, remova-a(s) antes de remover este motivo!`
              );
              setIsActionCompleted(false);
            } else {
              setTitleText("Motivo de reserva removido com sucesso!");
              setIsActionCompleted(true);
            }
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
          })
          .finally(() => {
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          });
      }
    })();
  };

  const renderDocuments = () => {
    if (!allDocuments.length) return;

    // sort labels alphabetically
    allDocuments.sort((a, b) => a.label.localeCompare(b.label));
    return React.Children.toArray(
      allDocuments.map((val) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: ".3rem",
              }}
            >
              {val.label}
              <div>
                <FaPencilAlt
                  className="icon-list icon---orange"
                  style={{
                    marginRight: ".3rem",
                  }}
                  onClick={() => {
                    setIsEdit(true);
                    setDocumentId(val.value);
                    toggleEdit();
                    toggle();
                  }}
                />
                <FaRegTrashAlt
                  className="icon-list icon-trash"
                  onClick={() => {
                    setClickedId(val.value);
                    toggleConfirmationModal();
                  }}
                />
              </div>
            </div>
            <hr />
          </>
        );
      })
    );
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {listType === "floor"
                  ? "Listar Pisos"
                  : listType === "area"
                  ? "Listar Áreas"
                  : listType === "space"
                  ? "Listar Espaços"
                  : listType === "resource"
                  ? "Listar Recursos"
                  : listType === "reason"
                  ? "Listar Motivos de Reserva"
                  : "Listar Tipos de Espaços"}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {allDocuments.length === 0 ? (
                <h6>Não há nada para listar.</h6>
              ) : null}
              {renderDocuments()}
            </Modal.Body>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={
              listType === "floor"
                ? deleteFloor
                : listType === "area"
                ? deleteArea
                : listType === "space"
                ? deleteSpace
                : listType === "resource"
                ? deleteResource
                : listType === "reason"
                ? deleteReason
                : deleteSpaceType
            }
            titleText={
              listType === "floor"
                ? "Remover Piso"
                : listType === "area"
                ? "Remover Área"
                : listType === "space"
                ? "Remover Espaço"
                : listType === "resource"
                ? "Remover Recurso"
                : listType === "reason"
                ? "Remover Motivo de Reserva"
                : "Remover Tipo de Espaço"
            }
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default ListFloorAreaSpaceModal;
