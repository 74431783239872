import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useModal from '../../utils/useModal'
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const SelectEditFloor = ({
  isShowing,
  toggle,
  setFloorId,
  floorId,
  toggleEditFloorModal,
  isEdit,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
}) => {
  const [allFloorsOptions, setAllFloors] = useState([]);
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();
  const [selectedFloor, setSelectedFloor] = useState(null);
  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const handleChange = (selected) => {
    setSelectedFloor(selected);
    setFloorId(selected.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      toggleEditFloorModal();
      toggle();
    } else {
      toggleConfirmationModal();
    }
  };

  const deleteFloor = () => {
    (async () => {
      if (floorId !== null) {
        await axios
          .delete(`${API_ROUTE}floor/${floorId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            setTitleText("Piso removido com sucesso!");
            setIsActionCompleted(true);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
            // alert(err);
          });
      }
    })();
  };

  // get all Floors to create the select input
  useEffect(() => {
    const fetchFloors = async () => {
      try {
        const response = await axios.get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
          withCredentials: true,
        });
        const floors = response.data.data.allFloorsBySchool || [];

        const formattedFloors = floors.map((floor) => ({
          value: floor._id,
          label: floor.name,
        }));

        if (formattedFloors.length > 0) {
          setSelectedFloor(formattedFloors[0]);
          setFloorId(formattedFloors[0].value);
          setAllFloors(formattedFloors);
        }
      } catch (error) {
        console.error('Error fetching floors:', error);
      }
    };

    if (selectedSchoolCode) {
      fetchFloors();
    }
  }, [selectedSchoolCode]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit ? "Editar Piso" : "Remover Piso"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    {isEdit
                      ? "Selecione um piso para editar"
                      : "Selecione um piso para remover"}
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um piso"
                    options={allFloorsOptions}
                    className="react-select"
                    value={selectedFloor}
                    onChange={handleChange}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  {isEdit ? "Selecionar Piso" : "Remover Piso"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteFloor}
            titleText={"Remover piso"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectEditFloor;
