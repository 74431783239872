import React, {useState, useEffect, useContext} from "react";
import axios from "axios";
import { Button } from "react-bootstrap";

import "./floors.styles.scss";

import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const FloorsPage = () => {
  const [allFloors, setAllFloors] = useState([]);
  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  useEffect(() => {
    let didCancel = false;
    (async () => {
      await axios
        .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          if(didCancel) return;
          setAllFloors(res.data.data.allFloorsBySchool);
        })
        .catch((err) => {
          console.log(err.response);
        });
    })();

    return () => { didCancel = true; }
  }, [setSelectedSchoolCode]);

  const createButtons = () => {
    return React.Children.toArray(
      allFloors.map((floor) => {
        return (
          <Button
            variant="outline-info"
            size="lg"
            block
            href={`/floor/${floor._id}/`}
          >
            {floor.name}
          </Button>
        );
      })
    );
  };

  return (
    <div className="homepage__layout">
      <div className="homepage__text">
        <p>
          Selecione um piso para começar a navegar entre as áreas e espaços.
        </p>
      </div>
      <div className="homepage__btnColumn">{createButtons()}</div>
    </div>
  );
};

export default FloorsPage;
