import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "react-bootstrap";
import axios from "axios";
import { connect } from "react-redux";

import BreadCrumb from "../../components/breadcrumb/breadcrumb.component";
import SpecificArea from "../../components/specific-area/specific-area.component";
import WithSpinner from "../../components/spinner/with-spinner.component";
import SideInfo from "../../components/side-info/side-info.component";

import "./area.styles.scss";

import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";

const SpecificAreaWithSpinner = WithSpinner(SpecificArea);

class Area extends React.Component {
  constructor() {
    super();
    this.state = {
      id: null,
      breadCrumbArray: [],
      areaName: "",
      floorName: "",
      areaObjectData: {},
      spaceData: [],
      allFloorsName: [],
      allFloorsId: [],
      allAreasName: [],
      allAreasId: [],
      coordsAndId: [],
      isLoading: true,
      showAllSelectableArea: false,
    };
  }

  getData = async () => {
    await axios
      .get(`${API_ROUTE}area/${this.props.match.params.areaId}`, {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({ areaName: res.data.data.areaData.name + ` (${res.data.data.schoolName})` });
        this.setState({ areaObjectData: res.data.data.areaData });
        this.setState({ spaceData: res.data.data.spaceData }, () => {
          this.setState({
            coordsAndId: this.state.spaceData.map((val) => {
              return { coords: val.coordinates, id: val._id };
            }),
          });
        });
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });

    await axios
      .get(`${API_ROUTE}floor/${this.props.match.params.floorId}`, {
        withCredentials: true,
      })
      .then((res) => {
        this.setState({ floorName: res.data.data.floorData.name });
      });

    await axios
      .get(`${API_ROUTE}floor/school/${this.context.selectedSchoolCode}`, {
        withCredentials: true,
      })
      .then((res) => {
        let tmpFloorsNames = [];
        let tmpFloorsIds = [];

        res.data.data.allFloorsBySchool.forEach((element) => {
          tmpFloorsNames.push(element.name);
          tmpFloorsIds.push("/floor/" + element._id + "/");
        });

        this.setState({ allFloorsName: tmpFloorsNames });
        this.setState({ allFloorsId: tmpFloorsIds });
      });

    await axios
      .get(`${API_ROUTE}area/byFloor/${this.props.match.params.floorId}`, {
        withCredentials: true,
      })
      .then((res) => {
        let tmpAreasName = [];
        let tmpAreasId = [];
        res.data.data.allAreas.forEach((element) => {
          tmpAreasName.push(element.name);

          tmpAreasId.push(
            "/floor/" +
              this.props.match.params.floorId +
              "/area/" +
              element._id +
              "/"
          );
        });

        this.setState({ allAreasName: tmpAreasName });
        this.setState({ allAreasId: tmpAreasId });
      });
  };

  async componentDidMount() {
    await this.getData();
    const tmpBreadCrumb = [
      {
        name: "Pisos",
        isToggle: true,
        items: this.state.allFloorsName,
        links: this.state.allFloorsId,
      },
      {
        name: this.state.floorName,
        links: `/floor/${this.props.match.params.floorId}/`,
        isToggle: false,
      },
      {
        name: "Áreas",
        isToggle: true,
        items: this.state.allAreasName,
        links: this.state.allAreasId,
      },
      {
        name: `Area ${this.state.areaName}`,
        isToggle: false,
        isActive: true,
      },
    ];
    this.setState({ breadCrumbArray: tmpBreadCrumb });
  }

  setId = (id) => {
    if (!id) return;

    this.setState({ id });
  };

  removeId = () => {
    this.setState({ id: null });
  };

  goToSelected = () => {
    // put the code on callback queue so that the setState can finish before
    setTimeout(() => {
      this.props.history.push(`spaceinfo/${this.state.id}/`);
    }, 0);
  };

  setShowAllSelectableArea = () => {
    this.setState((prevState) => ({
      showAllSelectableArea: !prevState.showAllSelectableArea,
    }));
  };

  render() {
    const propsObj = {
      ...this.state.areaObjectData,
      coordinates: this.state.coordsAndId,
      setIdFunc: this.setId,
      removeIdFunc: this.removeId,
      goToSelected: this.goToSelected,
      id: this.state.id,
      showAllSelectableArea: this.state.showAllSelectableArea,
    };
    return (
      <div className="areaWrapper">
        <div className="areaContent">
          <h3>{this.state.areaName}</h3>

          <div className="breadcrumb-custom">
            <BreadCrumb
              items={this.state.breadCrumbArray}
              history={this.props.history}
            />
          </div>

          <Button
            onClick={this.setShowAllSelectableArea}
            size="sm"
            className="area-btn"
            variant="info"
          >
            Mostrar todas as áreas selecionáveis
          </Button>

          <SpecificAreaWithSpinner
            isLoading={this.state.isLoading}
            {...propsObj}
            isFloor={false}
            isSystemManager={
              this.props.employeeRole === "system-manager"
            }
          />
        </div>

        <div className="areaCalendar">
          {this.state.id !== null
            ? React.Children.toArray([
                <SideInfo
                  {...this.state.spaceData[
                    this.state.spaceData.findIndex(
                      (val) => val._id === this.state.id
                    )
                  ]}
                  history={this.props.history}
                  spaceId={this.state.id}
                  isSystemManager={
                    this.props.employeeRole === "system-manager"
                  }
                />,
                <Button
                  onClick={this.goToSelected}
                  className="calendar-btn"
                  variant="secondary"
                >
                  Ver Mais Informações
                </Button>,
              ])
            : ""}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  employeeRole: state.employee.employeeRole,
});

Area.contextType = GlobalContext;

export default connect(mapStateToProps)(withRouter(Area));
