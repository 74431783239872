import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import useModal from '../../utils/useModal'
import ConfirmationModal from "../confirmation-modal/confirmation-modal.component";
import API_ROUTE from "../../utils/apiRoute";
import {GlobalContext} from "../../GlobalState";
const SelectDeleteSpaceTypeModal = ({
  isShowing,
  toggle,
  setTypeId,
  typeId,
  toggleEditSpaceTypeModal,
  isEdit,
  setTitleText,
  toggleActionFeedback,
  setIsActionCompleted,
  setTypeName,
}) => {
  const [allTypes, setAllTypes] = useState([]);
  const { isShowing: isShowingConfirmationModal, toggle: toggleConfirmationModal } = useModal();

  const [selectedSpaceType, setSelectedSpaceType] = useState(null);
  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const handleChange = (selected) => {
    setSelectedSpaceType(selected);
    setTypeId(selected.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEdit) {
      toggleEditSpaceTypeModal();
      (async () => {
        await axios
          .get(`${API_ROUTE}type/${typeId}`, {
            withCredentials: true,
          })
          .then((res) => {
            setTypeName(res.data.data.type.name);
          })
          .catch((err) => {
            if (err.response.data.message) {
              setTitleText(err.response.data.message);
            } else {
              setTitleText(
                "Algo inesperado aconteceu, tente novamente mais tarde!"
              );
            }
          });
      })();
      toggle();
    } else {
      toggleConfirmationModal();
    }
  };

  const deleteSpaceType = () => {
    (async () => {
      if (typeId !== null) {
        await axios
          .delete(`${API_ROUTE}type/${typeId}/`, {
            withCredentials: true,
          })
          .then((res) => {
            const spacesUsingThisType = res.data.data.spaces;
            if (spacesUsingThisType.length > 0) {
              const spacesNames = spacesUsingThisType.map(
                (space) => space.name
              );
              setTitleText(
                `O(s) seguinte(s) espaço(s) utilizam este tipo: ${spacesNames}, altere o(s) tipo(s) antes de removê-lo(s)!`
              );
              setIsActionCompleted(false);
            } else {
              setTitleText("Tipo de espaço removido com sucesso!");
              setIsActionCompleted(true);
            }
          })
          .catch((err) => {
            setTitleText("Algo inesperado aconteceu, tente novamente!");
            setIsActionCompleted(false);
          })
          .finally(() => {
            toggleActionFeedback();
            toggleConfirmationModal();
            toggle();
          });
      }
    })();
  };

  // get all types to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}type/school/${selectedSchoolCode}`, {
          withCredentials: true,
        })
        .then((res) => {
          const tmpAllTypes = res.data.data.allTypesBySchool.map((type) => {
            return {
              value: type._id,
              label: type.name,
            };
          });
          if (tmpAllTypes.length > 0) {
            setSelectedSpaceType(tmpAllTypes[0]);
            setTypeName(tmpAllTypes[0].label);
            setTypeId(tmpAllTypes[0].value); // default initial value
            setAllTypes(tmpAllTypes);
          }
        });
    })();
  }, [setSelectedSchoolCode]);

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      color: state.isSelected ? "#FFF" : styles.color,
      borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
      "&:hover": {
        color: "#FFF",
        backgroundColor: "#2684FF",
      },
    }),
    control: (base, state) => {
      let statusColor = colors.divider;

      if (state.selectProps.error) {
        // "state.selectProps" references the component props
        statusColor = colors.error;
      }

      return {
        ...base,
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgba(120, 194, 173, 0.25)"
          : 0,
        borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        "&:hover": {
          borderColor: state.isFocused ? "#2684FF" : "#CED4DA",
        },
      };
    },
  };

  const colors = {
    divider: "#34eb49",
    error: "#ff3447",
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              toggle();
            }}
          >
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {isEdit ? "Editar Tipo de Espaço" : "Remover Tipo de Espaço"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomCoordinates">
                  <Form.Label>
                    {isEdit
                      ? "Selecione um tipo de espaço para editar"
                      : "Selecione um tipo de espaço para remover"}
                  </Form.Label>
                  <Select
                    placeholder="Selecione ou digite o nome de um tipo de espaço"
                    options={allTypes}
                    className="react-select"
                    value={selectedSpaceType}
                    onChange={handleChange}
                    styles={customStyles}
                    error={true}
                  />
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  {isEdit
                    ? "Selecionar Tipo de Espaço"
                    : "Remover Tipo de Espaço"}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ConfirmationModal
            isShowing={isShowingConfirmationModal}
            toggle={toggleConfirmationModal}
            handleConfirmation={deleteSpaceType}
            titleText={"Remover Tipo de Espaço"}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default SelectDeleteSpaceTypeModal;
