import React, { useState, useMemo } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import API_ROUTE from "../../utils/apiRoute";

const LoginPage = ({
  setAuthenticated,
  setUserName,
  setSystemMasterManager,
  setSystemManager,
  setSpaceManager,
}) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const [isInvalidAuth, setIsInvalidAuth] = useState(false);

  const emailRef = useState(React.createRef())[0];
  const passwordRef = useState(React.createRef())[0];

  const onSubmit = async (e) => {
    e.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    await axios
      .post(
        `${API_ROUTE}employee/login`,
        { email, password },
        { withCredentials: true }
      )
      .then((res) => {
        if (res.data.user.role === "system-master-manager") {
          setSystemMasterManager();
        }
        if (res.data.user.role === "system-manager") {
          setSystemManager();
        }
        if (res.data.user.role === "space-manager") {
          setSpaceManager();
        }
        dispatch({ type: "SET_EMPLOYEE_ROLE", payload: res.data.user.role });
        dispatch({ type: "SET_EMPLOYEE_NAME", payload: res.data.user.name });
        setUserName(res.data.user.name);
        setAuthenticated();
        history.push("/home");
        window.location.reload();
      })
      .catch((err) => {
        setIsInvalidAuth(true);
      });
  };

  const renderError = useMemo(
    () =>
      isInvalidAuth ? (
        <Form.Text style={{ color: "red", marginBottom: "1.5vh" }}>
          Autenticação inválida!
        </Form.Text>
      ) : null,
    [isInvalidAuth]
  );

  return (
    <div className="">
      <Form>
        <Form.Group controlId="formBasicEmail" style={{ marginTop: "2rem" }}>
          <Form.Label>Id de Utilizador</Form.Label>
          <Form.Control
            ref={emailRef}
            type="email"
            placeholder="Digite o nome de usuário"
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Senha</Form.Label>
          <Form.Control
            ref={passwordRef}
            type="password"
            placeholder="Digite a senha"
          />
        </Form.Group>
        {renderError}
        <Button variant="primary" type="submit" onClick={onSubmit}>
          Entrar
        </Button>
      </Form>
    </div>
  );
};

export default LoginPage;
