import React, {useContext, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import { Modal, Form, Button } from "react-bootstrap";
import axios from "axios";
import useModal from '../../../utils/useModal'

import ActionFeedbackModal from "../../action-feedback-modal/action-feedback-modal.component";
import API_ROUTE from "../../../utils/apiRoute";
import {GlobalContext} from "../../../GlobalState";

const CreateAreaModal = ({ isShowing, toggle, isArea }) => {
  const [allFloors, setAllFloors] = useState([]);
  const [validated, setValidated] = useState(false);

  const { isShowing: isShowingActionFeedbackModal, toggle: toggleActionFeedbackModal } = useModal();
  const [titleText, setTitleText] = useState("");
  const [isActionCompleted, setIsActionCompleted] = useState(false);

  const [imgVal, setImgVal] = useState(null);

  const nameRef = useState(React.createRef())[0];
  const floorRef = useState(React.createRef())[0];
  const coordinatesRef = useState(React.createRef())[0];
  const imageRef = useState(React.createRef())[0];

  const { selectedSchoolCode, setSelectedSchoolCode } = useContext(GlobalContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === true) {
      let bodyFormData = new FormData();
      bodyFormData.append("name", nameRef.current.value);
      bodyFormData.append("schoolCode", selectedSchoolCode);
      bodyFormData.append("img", imgVal);

      if (isArea) {
        bodyFormData.append("floor", floorRef.current.value);
        bodyFormData.append("coordinates", coordinatesRef.current.value);

        (async () => {
          await axios
            .post(`${API_ROUTE}area`, bodyFormData, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Área criada com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedbackModal();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedbackModal();
            });
        })();
      } else {
        (async () => {
          await axios
            .post(`${API_ROUTE}floor`, bodyFormData, {
              withCredentials: true,
            })
            .then((res) => {
              setTitleText("Piso criado com sucesso!");
              setIsActionCompleted(true);
              toggleActionFeedbackModal();
            })
            .catch((err) => {
              if (err.response.data.message) {
                setTitleText(err.response.data.message);
              } else {
                setTitleText(
                  "Algo inesperado aconteceu, tente novamente mais tarde!"
                );
              }
              setIsActionCompleted(false);
              toggleActionFeedbackModal();
            });
        })();
      }
    } else {
      event.stopPropagation();
    }

    setValidated(true);
  };

  // get all Floors data to create the select input
  useEffect(() => {
    (async () => {
      await axios
        .get(`${API_ROUTE}floor/school/${selectedSchoolCode}`, { withCredentials: true })
        .then((res) => {
          setAllFloors(res.data.data.allFloorsBySchool);
        });
    })();
  }, [setSelectedSchoolCode]);

  // create Floor options
  const createOptions = () => {
    return React.Children.toArray(
      allFloors.map((floor) => {
        return <option value={floor.name}>{floor.name}</option>;
      })
    );
  };

  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Modal
            show={isShowing}
            onHide={() => {
              setValidated(false);
              toggle();
            }}
          >
            <Form
              noValidate
              // method="post"
              // action="${API_ROUTE}area"
              encType="multipart/form-data"
              validated={validated}
              onSubmit={handleSubmit}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {isArea ? "Criar Área" : "Criar Piso"}
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form.Group controlId="validationCustomName">
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder={isArea ? "Nome da Área" : "Nome do Piso"}
                    name="name"
                    ref={nameRef}
                  />
                  <Form.Control.Feedback type="invalid">
                    {isArea
                      ? "A área precisa de um nome!"
                      : "O piso precisa de um nome!"}
                  </Form.Control.Feedback>
                </Form.Group>
                {isArea ? (
                  <Form.Group controlId="validationCustomFloor">
                    <Form.Label>Piso</Form.Label>
                    <Form.Control
                      as="select"
                      name="floor"
                      required
                      ref={floorRef}
                    >
                      {createOptions()}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      A área precisa de um piso!
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}

                {isArea ? (
                  <Form.Group controlId="validationCustomCoordinates">
                    <Form.Label>Coordernadas</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Coordenadas da Área"
                      name="coordinates"
                      required
                      ref={coordinatesRef}
                    />
                    <Form.Control.Feedback type="invalid">
                      A área precisa de Coordernadas!
                    </Form.Control.Feedback>
                  </Form.Group>
                ) : null}

                <Form.Group controlId="validationCustomImage">
                  <Form.Label>Imagem</Form.Label>
                  <Form.Control
                    type="file"
                    name="img"
                    required
                    accept=".jpeg, .png"
                    ref={imageRef}
                    onChange={(evt) => {
                      setImgVal(evt.target.files[0]);
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {isArea
                      ? "A área precisa de uma imagem!"
                      : "O piso precisa de uma imagem!"}
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                    Extensões aceitas: jpeg, jpg, png.
                  </Form.Text>
                </Form.Group>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={toggle}>
                  Cancelar
                </Button>
                <Button type="submit" variant="success">
                  Confirmar
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <ActionFeedbackModal
            isShowing={isShowingActionFeedbackModal}
            toggle={toggleActionFeedbackModal}
            isActionCompleted={isActionCompleted}
            titleText={titleText}
          />
        </React.Fragment>,
        document.body
      )
    : null;
};

export default CreateAreaModal;
