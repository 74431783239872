import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from "axios";
import API_ROUTE from "../../utils/apiRoute";

const ProfilePage = () => {

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [isFromLdap, setIsFromLdap] = useState(false);
    const [isProfessor, setIsProfessor] = useState(false);

    const [triggerEffect, setTriggerEffect] = useState(false);

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get(`${API_ROUTE}employee/infos`, {
                    withCredentials: true,
                });

                const { _id, name, email, role, schoolName, isFromLdap, isProfessor } = response.data.user;

                setId(_id);
                setName(name);
                setEmail(email);
                setSchoolName(schoolName ?? 'Escola Indefinida');
                setIsFromLdap(isFromLdap);
                setIsProfessor(isProfessor);

                switch (role) {
                    case 'system-master-manager':
                        setRole("Administrador Geral do sistema");
                        break;
                    case 'system-manager':
                        setRole("Administrador do sistema");
                        break;
                    case 'space-manager':
                        setRole("Gestor de Espaços");
                        break;
                    default:
                        setRole("Utilizador Comum");
                }

            } catch (error) {
                console.error(error);
            }
        };

        fetchProfileData();
    }, [triggerEffect]);

    const handleUpdate = async () => {
        try {
            await axios.patch(`${API_ROUTE}employee/${id}/from-ldap`, {},{
                withCredentials: true,
            });
            setTriggerEffect(!triggerEffect);
            alert('Sucesso ao atualizar os dados');
        } catch (error) {
            console.error(error);
            alert('Falha ao atualizar os dados');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <Card>
                        <Card.Header className="text-center">
                            <h2>Perfil</h2>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text>
                                <strong>Nome:</strong> {name}
                            </Card.Text>
                            <Card.Text>
                                <strong>Email:</strong> {email}
                            </Card.Text>
                            <Card.Text>
                                <strong>Tipo de utilizador:</strong> {role}
                            </Card.Text>
                            <Card.Text>
                                <strong>Função:</strong> {isProfessor ? 'Docente' : 'Não Docente'}
                            </Card.Text>
                            <Card.Text>
                                <strong>Escola:</strong> {schoolName}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            { isFromLdap ? (
                <Row className="justify-content-md-center mt-3">
                    <Col md={6} className="text-center">
                        <Button variant="primary" onClick={handleUpdate}>
                            Atualizar Dados Através do LDAP
                        </Button>
                    </Col>
                </Row> ) : null
            }
        </Container>
    );
};

export default ProfilePage;
